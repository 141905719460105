import Vue from "vue";
import Vuex from "vuex";
import Services from "./services.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    lessons: []
  },

  actions: {
    fecthLessons() {
      // make the call
    }
  },
  getters: {
    lessonsCount() {}
  },
  mutations: {}
});
export default store;
