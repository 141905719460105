import Vue from "vue";
import Buefy from "buefy";
import FixedHeader from "vue-fixed-header";
import VueScrollTo from "vue-scrollto";
import Scrollspy from "vue2-scrollspy";
import Affix from "vue-affix";
import VeeValidate from "vee-validate";

var mailcheck = require("mailcheck");

import store from "./store";

import "./style/main.scss";
import "./scripts/navbar.js";

Vue.use(Buefy, {
  defaultIconPack: "mdi"
});
Vue.use(VueScrollTo);
Vue.use(Scrollspy);
Vue.use(Affix);
Vue.use(VeeValidate);

Vue.directive("scroll", {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  }
});

import Hero from "./components/Hero.vue";
import LessonListItem from "./components/LessonListItem.vue";
import ShowLesson from "./views/ShowLesson.vue";

new Vue({
  el: "#app",
  store,
  data () {
    return {
      isFixed: false,
      isSwitched: false,
      firstname: "",
      email: "",
      repemail: "",
      password: "",
      mailCheckedEmail: undefined,
      isComponentModalActive: false,
      lostPassword: false,
      isSupportModalActive: false
    };
  },
  components: {
    Hero,
    "fixed-header": FixedHeader,
    "lesson-list-item": LessonListItem,
    "show-lesson": ShowLesson
  },
  methods: {
    handleScroll: function (evt, el) {
      if (window.scrollY > 100) {
        el.setAttribute("style", "transform: rotate(0deg);");
      }
      return window.scrollY > 200;
    },
    verifyEmail: function () {
      let self = this;
      mailcheck.run({
        email: self.email,
        suggested: function (suggestion) {
          self.mailCheckedEmail = suggestion.full;
        },
        empty: function () { }
      });
    },
    setEmail: function () {
      this.email = this.mailCheckedEmail;
      this.mailCheckedEmail = undefined;
    },
    changeLocale (locale) {
      i18n.locale = locale;
    }
  }
});
