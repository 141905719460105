var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { attrs: { id: "hero" } }, [
    _c("div", { staticClass: "hero-body" }, [
      _c("div", { staticClass: "container" }, [
        _c("h1", { staticClass: "title" }, [
          _vm._v("\n            " + _vm._s(_vm.title) + "\n          ")
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "subtitle" }, [
          _vm._v("\n            " + _vm._s(_vm.subtitle) + "\n          ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }