<template>

  <div
    class="lesson-list-item"
    :class="status"
  >
    <div class="circle lesson-list-item-number">
      <span><i class="mdi mdi-book-open-variant mdi-24px"></i></span>
    </div>
    <div class="lesson-list-item-detail">

      <p class="has-text-primary is-uppercase">{{ level }}</p>

      <p class="title has-text-primary">{{ title }}</p>
      <p>{{ excerpt }}</p>
      <p class="has-text-centered">
        <br>
        <a
          title="Disabled"
          disabled="disabled"
          class="button is-rounded release-date"
        >To be released on {{ releasedate }}</a>
      </p>
    </div>
    <div class="lesson-list-item-action has-text-centered">
      <a
        :href="permalink"
        class="button is-medium is-primary is-rounded read-now"
      >
        <span>Read</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "lesson-list-item",
  props: ["title", "permalink", "cover", "status", "level", "releasedate", "excerpt", "lessonnumber"]
};
</script>

<style lang="scss" scoped>
.circle {
  display: flex;
  cursor: pointer;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}

.circle.lesson-list-item-number {
  justify-content: center;
  background-color: #d0d1cd;
}

.lesson-list-item.open {
  .circle {
    background-color: #8025db;
    color: #fff;
  }
}
</style>
