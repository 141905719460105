<template>
  <div
    class="wrapper"
    id="lesson"
  >
    <section v-if="error">
      <div class="container has-text-centered">
        <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
      </div>
    </section>

    <section v-else>
      <div class="show-content">
        <fixed-header
          :fixed.sync="isFixed"
          :threshold="700"
        >
          <header
            class="header"
            :class="{ 'is-fixed': isFixed }"
          >
            <nav class="navbar-reader">
              <div class="left">
                <a
                  href="/dashboard/my-lessons"
                  class="navbar-reader-item"
                  title="Close Lesson"
                >
                  <b-icon icon="arrow-left"></b-icon>
                </a>
                <span
                  class="navbar-reader-item toggle"
                  v-click-outside="hide"
                  @click="toggle()"
                >
                  <b-icon icon="menu"></b-icon>
                </span>
              </div>
              <div class="right">
                <!--
                <a href="#" class="navbar-reader-item">
                  <b-icon icon="format-font"></b-icon>
                </a>
                <a href="#" class="navbar-reader-item">
                  <b-icon icon="file-document-box-outline"></b-icon>
                </a>
                -->
              </div>
            </nav>
          </header>
        </fixed-header>

        <div
          class="cover"
          v-bind:style="{ 'background-image': 'url(' + cover + ')' }"
        >
          <div class="start-reading">
            <span class="scroll-down is-size-7 is-uppercase has-text-white">Start reading</span>
            <a
              href="#lessonContent"
              v-scroll-to="'#lessonContent'"
            >
              <b-icon
                icon="chevron-down"
                size="is-large"
              ></b-icon>
            </a>
          </div>
        </div>
        <div
          class="lesson-content"
          id="lessonContent"
        >
          <b-loading
            :is-full-page="isFullPage"
            :active.sync="isLoading"
            :can-cancel="false"
          ></b-loading>
          <div class="container">
            <div
              class="content is-large has-text-serif"
              v-html="lesson.content ? lesson.content.rendered : 0"
            ></div>
          </div>
        </div>
      </div>
    </section>

    <aside
      class="sidenav menu"
      v-show="isOpen"
      v-bind:class="{ active: isOpen }"
    >
      <p class="menu-label">Table of Contents</p>
      <ul class="menu-list">
        <li v-for="item in lesson.acf ? lesson.acf.theme_lesson_table_of_contents: 0">
          <a
            :href="item.table_contents_item_link"
            v-on:click="isActive = item"
            v-bind:class="{ active: isActive == item}"
            v-scroll-to="item.table_contents_item_link"
          >{{ item.table_contents_item_title }}</a>
        </li>
      </ul>
      <!--
      <p class="menu-label">
        Resources
      </p>
      <ul class="menu-list">
        <li v-for="item in lesson.acf ? lesson.acf.lesson_resources: 0">
          <a
            :href="item.lesson_upload_file"
            target="_blank"
          >
            {{ item.lesson_resource_file_title }}
          </a>
        </li>
      </ul>
      -->
    </aside>
  </div>
</template>

<script>
import Services from "../services.js";
import FixedHeader from "vue-fixed-header";
import ClickOutside from "vue-click-outside";

export default {
  name: "show-lesson",
  props: ["id", "cover"],
  components: {
    FixedHeader
  },
  data () {
    return {
      lesson: {},
      isFixed: false,
      isFullPage: true,
      isLoading: true,
      isOpen: false,
      isActive: true,
      error: false
    };
  },
  created () {
    Services.getLesson(this.id)
      .then(response => {
        this.lesson = response.data;
      })
      .catch(error => {
        console.log("There was an error:", error.response);
        this.error = true;
      })
      .finally(() => (this.isLoading = false));
  },
  methods: {
    toggle: function () {
      this.isOpen = !this.isOpen;
    },
    hide () {
      this.isOpen = false;
    }
  },
  directives: {
    ClickOutside
  }
};
</script>

<style lang="scss" scoped>
.kof-lesson {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas: "header" "cover" "lesson-content";
    width: 100%;
    .header {
      grid-area: header;
      position: fixed;
      display: flex;
      height: 80px;
      padding: 0 20px;
      align-items: center;
      color: #fff;
      width: 100%;
      z-index: 10000;
      .navbar-reader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .navbar-reader-item {
          color: #8d7fd8;
        }
        .navbar-reader-item.toggle {
          cursor: pointer;
        }
      }
    }
    .header.is-fixed {
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0)
      );
      transition: background 0.15s ease-out;
      .navbar-reader {
        .navbar-reader-item {
          color: #35125c;
        }
      }
    }
    .cover {
      grid-area: cover;
      background-color: #15022b;
      min-height: 700px;
      text-align: center;
      position: relative;
      background-position: center 80%;
      background-size: cover;
      .start-reading {
        border-radius: 100%;
        width: 6rem;
        height: 4rem;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 4rem;
        margin: 0 auto;
        .icon {
          color: #fff;
        }
      }
    }
    .lesson-content {
      grid-area: lesson-content;
      background-color: rgba(255, 255, 255, 0.8);
      width: 100%;
      display: flex;
      padding-top: 100px;
      .container {
        padding: 0 20px;
        user-select: none;
        width: 100%;
        max-width: 700px;
      }
      .content {
        color: #000;
      }
    }
    .aside {
      grid-area: aside;
      background-color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
