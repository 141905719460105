var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrapper", attrs: { id: "lesson" } }, [
    _vm.error
      ? _c("section", [_vm._m(0)])
      : _c("section", [
          _c(
            "div",
            { staticClass: "show-content" },
            [
              _c(
                "fixed-header",
                {
                  attrs: { fixed: _vm.isFixed, threshold: 700 },
                  on: {
                    "update:fixed": function($event) {
                      _vm.isFixed = $event
                    }
                  }
                },
                [
                  _c(
                    "header",
                    {
                      staticClass: "header",
                      class: { "is-fixed": _vm.isFixed }
                    },
                    [
                      _c("nav", { staticClass: "navbar-reader" }, [
                        _c("div", { staticClass: "left" }, [
                          _c(
                            "a",
                            {
                              staticClass: "navbar-reader-item",
                              attrs: {
                                href: "/dashboard/my-lessons",
                                title: "Close Lesson"
                              }
                            },
                            [_c("b-icon", { attrs: { icon: "arrow-left" } })],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "click-outside",
                                  rawName: "v-click-outside",
                                  value: _vm.hide,
                                  expression: "hide"
                                }
                              ],
                              staticClass: "navbar-reader-item toggle",
                              on: {
                                click: function($event) {
                                  return _vm.toggle()
                                }
                              }
                            },
                            [_c("b-icon", { attrs: { icon: "menu" } })],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "right" })
                      ])
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cover",
                  style: { "background-image": "url(" + _vm.cover + ")" }
                },
                [
                  _c("div", { staticClass: "start-reading" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "scroll-down is-size-7 is-uppercase has-text-white"
                      },
                      [_vm._v("Start reading")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "scroll-to",
                            rawName: "v-scroll-to",
                            value: "#lessonContent",
                            expression: "'#lessonContent'"
                          }
                        ],
                        attrs: { href: "#lessonContent" }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "chevron-down", size: "is-large" }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "lesson-content",
                  attrs: { id: "lessonContent" }
                },
                [
                  _c("b-loading", {
                    attrs: {
                      "is-full-page": _vm.isFullPage,
                      active: _vm.isLoading,
                      "can-cancel": false
                    },
                    on: {
                      "update:active": function($event) {
                        _vm.isLoading = $event
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("div", {
                      staticClass: "content is-large has-text-serif",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.lesson.content ? _vm.lesson.content.rendered : 0
                        )
                      }
                    })
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
    _vm._v(" "),
    _c(
      "aside",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen"
          }
        ],
        staticClass: "sidenav menu",
        class: { active: _vm.isOpen }
      },
      [
        _c("p", { staticClass: "menu-label" }, [_vm._v("Table of Contents")]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "menu-list" },
          _vm._l(
            _vm.lesson.acf ? _vm.lesson.acf.theme_lesson_table_of_contents : 0,
            function(item) {
              return _c("li", [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "scroll-to",
                        rawName: "v-scroll-to",
                        value: item.table_contents_item_link,
                        expression: "item.table_contents_item_link"
                      }
                    ],
                    class: { active: _vm.isActive == item },
                    attrs: { href: item.table_contents_item_link },
                    on: {
                      click: function($event) {
                        _vm.isActive = item
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.table_contents_item_title))]
                )
              ])
            }
          ),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container has-text-centered" }, [
      _c("p", [
        _vm._v(
          "We're sorry, we're not able to retrieve this information at the moment, please try back later"
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }